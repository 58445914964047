import { ref, set, push, onValue, update } from "firebase/database";
import { database } from "../../../config/firebaseConfig";

const ticketModule = {
  state: () => ({
    tickets: [],
  }),

  mutations: {
    SET_TICKETS(state, tickets) {
      state.tickets = tickets;
    },
  },

  actions: {
    fetchTickets({ commit }) {
      const ticketsRef = ref(database, "tickets");
      onValue(ticketsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const tickets = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          commit("SET_TICKETS", tickets);
        }
      });
    },

    addTicket({ dispatch }, ticket) {
      const newTicket = {
        ...ticket,
        createdAt: new Date().toISOString(), // Fügt das Erstellungsdatum und die Uhrzeit hinzu
      };

      const newTicketRef = push(ref(database, "tickets"));
      set(newTicketRef, newTicket).then(() => dispatch("fetchTickets"));
    },

    markTicketAsDone({ dispatch }, ticketId) {
      const ticketRef = ref(database, `tickets/${ticketId}`);
      update(ticketRef, { done: true }).then(() => dispatch("fetchTickets"));
    },
    markTicketAsUndone({ dispatch }, ticketId) {
        const ticketRef = ref(database, `tickets/${ticketId}`);
        update(ticketRef, { done: false }).then(() => dispatch("fetchTickets"));
      },
  },

  getters: {
    Tickets: (state) => {
      return state.tickets;
    },
  },
};
export default ticketModule;
