import { firebaseConfig } from "../../../config/firebaseConfig";
import axios from "axios";
import router from "@/router";  // oder wo immer Ihr Router definiert ist

let timer;

const state = {
  userId: null,
  token: null,
  refreshToken: null, // Neues Feld für das Refresh-Token
  userName: null,
};
const mutations = {
  setUser(state, payload) {
    state.userId = payload.userId;
    state.token = payload.token;
    state.refreshToken = payload.refreshToken; // Das Refresh-Token setzen
    state.userName = payload.userName;
  },
};
const actions = {
  auth(context, payload) {
    let url = "";
    if (payload.mode === "signin") {
      url = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${firebaseConfig.apiKey}`;
    } else if (payload.mode === "signup") {
      url = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${firebaseConfig.apiKey}`;
    } else {
      return;
    }
    const authDO = {
      email: payload.email,
      password: payload.password,
      returnSecureToken: true,
    };
    return axios
      .post(url, authDO)
      .then((response) => {
        // Daten im LocalStorage speichern
        const expiresIn = Number(response.data.expiresIn) * 1000 ;
        // const expiresIn = 3 * 1000;
        const expDate = new Date().getTime() + expiresIn;

        console.log("auth-ExpiresIn", expiresIn)
        localStorage.setItem("token", response.data.idToken);
        localStorage.setItem("userId", response.data.localId);
        localStorage.setItem("userName", response.data.email);
        localStorage.setItem("expiresIn", expDate);
        localStorage.setItem("refreshToken", response.data.refreshToken); // Das Refresh-Token speichern

/*         timer = setTimeout(() => {
          context.dispatch("autoSignout");
        }, expiresIn); */
        timer = setTimeout(() => {
          context.dispatch("refreshToken"); // Hier das Refresh-Token verwenden
        }, expiresIn - 10);

        context.commit("setUser", {
          userId: response.data.localId,
          token: response.data.idToken,
          refreshToken: response.data.refreshToken, // Das Refresh-Token setzen
          userName: response.data.email,

        });
      })
      .catch((error) => {
        // console.log({ error });
        const errorMessage = new Error(
          error.response.data.error.message || "UNKNOWN_ERROR"
        );
        throw errorMessage;
      });
  },

  refreshToken(context) {
    const refreshToken = localStorage.getItem("refreshToken");
    const userName = localStorage.getItem("userName");

    if (!refreshToken) {
      context.dispatch("signout"); // Wenn kein Refresh-Token vorhanden ist, abmelden
      return;
    }
  
    const refreshTokenUrl = `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`; // Ersetze durch den tatsächlichen Auth-Server-Endpunkt
    const refreshTokenDO = {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    };
  
    axios
      .post(refreshTokenUrl, refreshTokenDO)
      .then((response) => {
        console.log("REFRESH-RESPONSE", response);
        const expiresIn = Number(response.data.expires_in) * 1000;
        console.log("expiresIn",expiresIn);
        const expDate = new Date().getTime() + expiresIn;
        console.log("expDate",expDate);

        localStorage.setItem("token", response.data.id_token);
        localStorage.setItem("expiresIn", expDate);

        localStorage.setItem("refreshed", true);
        localStorage.setItem("refreshToken", response.data.refresh_token); // Das Refresh-Token speichern
        
        context.commit("setUser", {
          token: response.data.idToken,
          refreshToken: refreshToken, // Das Refresh-Token bleibt unverändert
          userName: userName,
        });
  
        // Setze den Timer erneut für das aktualisierte Zugriffstoken
        clearTimeout(timer);
        timer = setTimeout(() => {
          context.dispatch("refreshToken");
          console.log("refresh");
        }, expiresIn);
      })
      .catch((error) => {
        console.error("Fehler bei der Aktualisierung des Zugriffstokens:", error);
        context.dispatch("signout"); // Bei einem Fehler das Benutzerkonto abmelden
      });
  },
  

  signup(context, payload) {
    const signupDO = {
      ...payload,
      mode: "signup",
    };
    return context.dispatch("auth", signupDO);
  },
  signin(context, payload) {
    const signinDO = {
      ...payload,
      mode: "signin",
    };
    return context.dispatch("auth", signinDO);
  },
  autoSignin(context) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");

    const expiresIn = localStorage.getItem("expiresIn");
    const timeLeft = Number(expiresIn) - new Date().getTime();
    console.log("timeleft:", timeLeft);

    if (timeLeft < 0) {
      return;
    }
   // context.dispatch("refreshToken");

    timer = setTimeout(() => {
      context.dispatch("autoSignout");
      router.push('/');
    }, timeLeft);

    if (token && userId) {
      context.commit("setUser", {
        token: token,
        userId: userId,
        userName: userName,
      });
    }
  },
  signout(context) {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("refreshToken"); // Das Refresh-Token entfernen

    clearTimeout(timer);

    context.commit("setUser", {
      token: null,
      userId: null,
      userName: null,
      refreshToken: null, // Das Refresh-Token zurücksetzen
    });
  },
  autoSignout(context) {
    // Server-Kommunikation, falls notwendig
    context.dispatch("signout");
  },
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  token: (state) => state.token,
  refreshToken: (state) => state.refreshToken, // Getter für das Refresh-Token
  userName: (state) => state.userName,
};

const authModule = {
  state,
  mutations,
  actions,
  getters,
};

export default authModule;
