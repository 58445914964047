

const adminRoutes = [
  {
    path: "/dashboard",
    component: () =>
      import(/*webpackChunkName: 'group-admin' */ "@/pages/DashboardPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/belegungen/zeitleiste",
    component: () =>
      import(/*webpackChunkName: 'group-admin' */ "@/pages/BelegungenZeitleistePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: "/belegungen/aktuelle",
    component: () =>
      import(/*webpackChunkName: 'group-admin' */ "@/pages/BelegungenAktuellPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/belegungen/wohnungenfrei",
    component: () =>
      import(/*webpackChunkName: 'group-admin' */ "@/pages/WohnungenFreiPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: "/belegungen",
    component: () =>
      //import(/*webpackChunkName: 'group-admin' */ "@/pages/BelegungenMainPage.vue"),
      import(/*webpackChunkName: 'group-admin' */ "@/pages/BelegungenZeitleistePage.vue"),

    meta: {
      requiresAuth: true,
    },
    redirect: '/belegungen/zeitleiste',
    
    /* children: [
      {
        path: "zeitleiste",
        component: () =>
      import( "@/pages/BelegungenPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      
    ], */
  },

  {
    path: "/wohnungen",
    component: () =>
      import(/*webpackChunkName: 'group-admin' */ "@/pages/WohnungenPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/einstellungen",
    component: () =>
      import(
        /*webpackChunkName: 'group-admin' */ "@/pages/ConfigurationPageTrigger.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/einstellungen/trigger",
    component: () =>
      import(
        /*webpackChunkName: 'group-admin' */ "@/pages/ConfigurationPageTrigger.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/einstellungen/tickets",
    component: () =>
      import(
        /*webpackChunkName: 'group-admin' */ "@/pages/ConfigurationPageTickets.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/einstellungen/abrechnungsintervalle",
    component: () =>
      import(
        /*webpackChunkName: 'group-admin' */ "@/pages/ConfigurationPageAbrechnungsintervalle.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/einstellungen/belegungsstatus",
    component: () =>
      import(
        /*webpackChunkName: 'group-admin' */ "@/pages/ConfigurationPageBelegungsstatus.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/auswertungen",
    component: () =>
      import(
        /*webpackChunkName: 'group-admin' */ "@/pages/auswertungen/UmsatzPage.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    redirect: '/auswertungen/umsatz',

  },
  {
    path: "/auswertungen/umsatz",
    component: () =>
      import(
        /*webpackChunkName: 'group-admin' */ "@/pages/auswertungen/UmsatzPage.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
];

export default adminRoutes;
