// store/dialog.js

const state = {
  isMsgDialogVisible: false,
  message: "",
  type: "info", // Kann 'info', 'error', 'success' usw. sein
};

const mutations = {
  showMsgDialog(state, { message, type = "info" }) {
    state.message = message;
    state.type = type;
    state.isMsgDialogVisible = true;
    console.log("MSGDIALOG2", message);
  },
  hideMsgDialog(state) {
    state.isMsgDialogVisible = false;
    state.message = "";
    state.type = "info";
  },
};

const actions = {
  triggerMsgDialog({ commit }, payload) {
    commit("showMsgDialog", payload);
    console.log("MSGDIALOG", payload);
  },
  messageBox({ commit }, payload) {
    commit("showMsgDialog", payload);
    
  },
  closeMsgDialog({ commit }) {
    commit("hideMsgDialog");
  },
};

const getters = {
  getVisibleState: (state) => {
    return state.isMsgDialogVisible;
  },
  getMessage: (state) => {
    return state.message;
  },
  getType: (state) => {
    return state.type;
  },
};

const dialogModule = {
  state,
  mutations,
  actions,
  getters,
};

export default dialogModule;
