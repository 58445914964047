import axios from "axios";
import { JUNKER_API_KEY } from "../../../config/junker.js";
//import { LEXOFFICE_API_KEY } from "../../../config/lexoffice.js";
import { apiConfig } from "@/config/firebaseConfig.js";

const state = {
  lexofficeContacts: null,
  loadingLexoffice: false,
  sendingLexoffice: false,
  sendingOrderConfirmation: false,
  orderConfirmationError: null,
};

const mutations = {
  setLexofficeContacts(state, lexofficeContacts) {
    state.lexofficeContacts = lexofficeContacts;
  },
  setLoadingLexoffice(state, loading) {
    state.loadingLexoffice = loading;
  },
  setSendingLexoffice(state, loading) {
    state.sendingLexoffice = loading;
  },
  // Mutationen für den Sendevorgang
  startSendingOrderConfirmation(state) {
    state.sendingOrderConfirmation = true;
    state.orderConfirmationError = null;
  },
  finishSendingOrderConfirmation(state, error = null) {
    state.sendingOrderConfirmation = false;
    state.orderConfirmationError = error;
  },
};

const actions = {
  fetchLexofficeContacts({ commit }) {
    commit("setLoadingLexoffice", true);
    axios
      .get(`${apiConfig.apiUrl}/api/lexofficekontakte?apiKey=${JUNKER_API_KEY}`)
      .then((response) => {
        commit("setLexofficeContacts", response.data);
        commit("setLoadingLexoffice", false);
        return response;
      })
      .catch((error) => {
        console.log("Es gab ein Problem mit dem Abruf der Daten:", error);
        commit("setLoadingLexoffice", false);
      });
  },

  /*  async sendOrderConfirmation(payload) {
    //const apiKey = JUNKER_API_KEY; // Hier Ihren tatsächlichen API-Key einsetzen
    //const apiUrl = `${apiConfig.apiUrl}/api/lexoffice/createorderconfirmation?apiKey=${apiKey}`;
  
    console.log("payload", payload);

     try {
      const response = await axios.get(apiUrl);
      // Je nach Antwort Ihrer API können Sie hier weitere Aktionen durchführen,
      // z.B. eine Erfolgsmeldung commiten oder eine Zustandsvariable setzen.
      console.log('AB executed successfully:', response.data);
    } catch (error) {
      console.error('Error executing trigger:', error);
      // Hier könnten Sie beispielsweise einen Fehler-Status in Ihrem Store setzen oder eine Fehlermeldung anzeigen.
    } 
  },*/

  async sendOrderConfirmation({ commit }, orderConfirmation) {
    commit("setSendingLexoffice", true);

    try {
      const response = await axios.post(
        `${apiConfig.apiUrl}/api/lexofficerechnung?apiKey=${JUNKER_API_KEY}`,
        orderConfirmation
      );
      commit("setSendingLexoffice", false);
      console.log("Auftragsbestätigung erfolgreich gesendet:", response);

      // Erfolgsobjekt zurückgeben
      return { success: true, data: response.data };
    } catch (error) {
      console.error(
        "Es gab ein Problem beim Senden der Rechnung:",
        error
      );
      commit("setSendingLexoffice", false);

      // Fehlerobjekt zurückgeben
      return { success: false, error: error };
    }
  },

  async sendCreditnote({ commit }, orderConfirmation) {
    commit("setSendingLexoffice", true);

    try {
      const response = await axios.post(
        `${apiConfig.apiUrl}/api/lexofficegutschrift?apiKey=${JUNKER_API_KEY}`,
        orderConfirmation
      );
      commit("setSendingLexoffice", false);
      console.log("Gutschrift erfolgreich gesendet:", response);

      // Erfolgsobjekt zurückgeben
      return { success: true, data: response.data };
    } catch (error) {
      console.error(
        "Es gab ein Problem beim Senden der Gutschrift:",
        error
      );
      commit("setSendingLexoffice", false);

      // Fehlerobjekt zurückgeben
      return { success: false, error: error };
    }
  },

  /* sendOrderConfirmation({ commit }, orderConfirmation) {
    // Stellen Sie sicher, dass Sie den API-Schlüssel für Lexoffice haben
    commit("setSendingLexoffice", true);

    // API-URL für Bestätigungen (überprüfen Sie die Dokumentation für die genaue URL)
    const url = `${apiConfig.apiUrl}/api/lexofficeauftrag?apiKey=${JUNKER_API_KEY}`;

    // Senden Sie die Bestätigung an Lexoffice
    axios
      .post(url, orderConfirmation)
      .then((response) => {
        console.log("Auftragsbestätigung erfolgreich gesendet:", response);
        commit("setSendingLexoffice", false);

        return response;
      })
      .catch((error) => {
        console.log(
          "Es gab ein Problem beim Senden der Auftragsbestätigung:",
          error
        );
        commit("setSendingLexoffice", false);
      });
  },
 */
};

const getters = {
  getLexofficeContacts: (state) => {
    return state.lexofficeContacts;
  },
  isLoadingLexoffice: (state) => {
    return state.loadingLexoffice;
  },
  isSendingLexoffice: (state) => {
    return state.sendingLexoffice;
  },
  // Getter für den Sendevorgang
  isSendingOrderConfirmation: (state) => {
    return state.sendingOrderConfirmation;
  },
  getOrderConfirmationError: (state) => {
    return state.orderConfirmationError;
  },
};

const lexofficeModule = {
  state,
  mutations,
  actions,
  getters,
};
export default lexofficeModule;
