import { createApp } from "vue";
import App from "./App.vue";
import store from "./store"; // Importieren Sie den Store hier
import router from "./router/index.js";
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';


const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");
