import axios from "axios";
import { JUNKER_API_KEY } from "../../../config/junker.js";
import { apiConfig } from "../../../config/firebaseConfig.js" 


const state = {
  exchangeusers: [],
  
};

const mutations = {
  SET_EXCHANGEUSERS(state, exchangeusers) {
    state.exchangeusers = exchangeusers;
  },
  

};

const actions = {
  
  loadExchangeusers({ commit }) {
    commit("SET_LOADING", true);
    axios
      .get(`${apiConfig.apiUrl}/api/graphusers?apiKey=${JUNKER_API_KEY}`)
      .then((response) => {
        commit("SET_EXCHANGEUSERS", response.data.value);
        commit("SET_LOADING", false);
        console.log(response.data.value);
      })
      .catch((error) => {
        console.log("Es gab ein Problem mit dem Abruf der Benutzerdaten:", error);
        commit("SET_LOADING", false);
      });
  },

  

  

  
};

const getters = {
  Exchangeusers: (state) => {
    return state.exchangeusers;
  },
  
};

const todoModule = {
  state,
  mutations,
  actions,
  getters,
};
export default todoModule;
