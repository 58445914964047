// Importiere die Firebase-Module
import { getDatabase, ref, set, push, update, remove, onValue } from 'firebase/database';
import axios from 'axios';
import { JUNKER_API_KEY } from "../../../config/junker.js";

//import { database } from "../../../config/firebaseConfig";
import { apiConfig } from "../../../config/firebaseConfig";

const state = {
  triggers: [],
};

const mutations = {
  setTriggers(state, payload) {
    state.triggers = payload;
  },
  addTrigger(state, payload) {
    state.triggers.push(payload);
  },
  updateTrigger(state, payload) {
    const index = state.triggers.findIndex(trigger => trigger.id === payload.id);
    if (index !== -1) {
      state.triggers[index] = payload;
    }
  },
  deleteTrigger(state, payload) {
    const index = state.triggers.findIndex(trigger => trigger.id === payload.id);
    if (index !== -1) {
      state.triggers.splice(index, 1);
    }
  },
};

const actions = {
  fetchTriggers(context) {
    const db = getDatabase();
    const triggersRef = ref(db, 'triggers/');
    onValue(triggersRef, (snapshot) => {
      const triggers = [];
      snapshot.forEach((childSnapshot) => {
        const id = childSnapshot.key;
        const data = childSnapshot.val();
        triggers.push({ id, ...data });
      });
      context.commit('setTriggers', triggers);
    });
  },
  async executeTrigger(context, payload) {
    const apiKey = JUNKER_API_KEY; // Hier Ihren tatsächlichen API-Key einsetzen
    const apiUrl = `${apiConfig.apiUrl}/api/firebase/executetrigger/${payload.triggerId}/${payload.belegungId}?apiKey=${apiKey}`;
  

    try {
      const response = await axios.get(apiUrl);
      // Je nach Antwort Ihrer API können Sie hier weitere Aktionen durchführen,
      // z.B. eine Erfolgsmeldung commiten oder eine Zustandsvariable setzen.
      console.log('Trigger executed successfully:', response.data);
    } catch (error) {
      console.error('Error executing trigger:', error);
      // Hier könnten Sie beispielsweise einen Fehler-Status in Ihrem Store setzen oder eine Fehlermeldung anzeigen.
    }
  },

  async storeTrigger( context , payload) {
    const db = getDatabase();
    const newTriggerRef = push(ref(db, 'triggers'));
    delete payload.id;
    set(newTriggerRef, payload)
      .then(() => {
        //payload.id = newTriggerRef.key;
        //context.commit('addTrigger', payload);
        context.dispatch("fetchTrigger"); // Belegungsliste aktualisieren

      });
  },

  updateTrigger(context, payload) {
    const { id, ...updateData } = payload; // Entfernen der ID aus dem Payload
    const db = getDatabase();
    const triggerRef = ref(db, `triggers/${id}`);
    update(triggerRef, updateData)
      .then(() => {
        //context.commit('updateTrigger', payload);
        context.dispatch("fetchTrigger"); // Belegungsliste aktualisieren

      });
  },
  deleteTrigger(context, payload) {
    const db = getDatabase();
    const triggerRef = ref(db, `triggers/${payload.id}`);
    remove(triggerRef)
      .then(() => {
        //context.commit('deleteTrigger', payload);
        context.dispatch("fetchTrigger"); // Belegungsliste aktualisieren

      });
  },
};

const getters = {
  Triggers: state => state.triggers,
  Trigger: state => id => state.triggers.find(trigger => trigger.id === id),

  activeAtBookingTriggers: (state) => {
    return state.triggers.filter(trigger => trigger.active && trigger.executiontime === 'ATBOOKING');
  },
  activeAtBillingTriggers: (state) => {
    return state.triggers.filter(trigger => trigger.active && trigger.executiontime === 'ATBILLING');
  },
  activeAtCreditnoteTriggers: (state) => {
    return state.triggers.filter(trigger => trigger.active && trigger.executiontime === 'ATGS');
  },

  activeAtCheckoutTriggers: (state) => {
    return state.triggers.filter(trigger => trigger.active && trigger.executiontime === 'ATCHECKOUTCONFIRMED');
  },
};

const triggerModule = {
  state,
  mutations,
  actions,
  getters,
};

export default triggerModule;
