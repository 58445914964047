import { ref, set, push, onValue, update, remove } from "firebase/database";
import { database } from "../../../config/firebaseConfig";

const state = {
  billingIntervals: [],
};

const mutations = {
  setBillingIntervals(state, payload) {
    state.billingIntervals = payload;
  },
  addBillingInterval(state, payload) {
    state.billingIntervals.push(payload);
  },
  updateBillingInterval(state, payload) {
    const index = state.billingIntervals.findIndex(interval => interval.id === payload.id);
    if (index !== -1) {
      state.billingIntervals[index] = payload;
    }
  },
  deleteBillingInterval(state, payload) {
    state.billingIntervals = state.billingIntervals.filter(interval => interval.id !== payload.id);
  },
};

const actions = {
  

  fetchBillingIntervals({ commit }) {
    const intervalRef = ref(database, "einstellungen/abrechnungsintervalle");
    onValue(intervalRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const billingIntervals = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        commit("setBillingIntervals", billingIntervals);
      }
    });
  },
  

  storeBillingInterval({ dispatch }, payload) {
    const newBillingIntervalRef = push(ref(database, 'einstellungen/abrechnungsintervalle'));
    set(newBillingIntervalRef, payload).then(() => dispatch("fetchBillingIntervals"));
  },
  updateBillingInterval({ dispatch }, payload) {
    const billingIntervalRef = ref(database, `einstellungen/abrechnungsintervalle/${payload.id}`);
    update(billingIntervalRef, payload).then(() => dispatch("fetchBillingIntervals"));
  },
  deleteBillingInterval({ dispatch }, payload) {
    const billingIntervalRef = ref(database, `einstellungen/abrechnungsintervalle/${payload.id}`);
    remove(billingIntervalRef).then(() => dispatch("fetchBillingIntervals"));
  },
};

const getters = {
  billingIntervals: state => state.billingIntervals,
  billingInterval: state => id => state.billingIntervals.find(interval => interval.id === id),
  billingIntervalFromTitle: state => title => state.billingIntervals.find(interval => interval.title === title),
  
};

const billingIntervalModule = {
  state,
  mutations,
  actions,
  getters,
};

export default billingIntervalModule;
