<template>
  <router-view> </router-view>
  <MsgDialog></MsgDialog> <!-- Dialog am Ende, um ihn über anderen Inhalten zu rendern -->
</template>

<script>
import MsgDialog from './components/dialogs/MsgDialog.vue';
export default {
  name: "App",
  components: {
    MsgDialog,
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
  },
  created() {
    this.$store.dispatch("autoSignin");
    console.log(this.$store);
  },
  watch: {
    token: {
      handler() {
        if (this.token) {
          this.$store.dispatch("fetchWohnungen");
          this.$store.dispatch("fetchBelegungen");
          this.$store.dispatch("fetchLexofficeContacts");
          this.$store.dispatch("loadExchangeusers");
          this.$store.dispatch("fetchTriggers");
          this.$store.dispatch("fetchBelegungsStatus");
          this.$store.dispatch("fetchBillingIntervals");
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "../node_modules/animate.css/animate.min.css";

@import "./styles.scss";

#app {
  /* display: flex; */
}

a {
  color: black;
}

header {
  height: 80px;
}
.h-100 {
  height: calc(100% - 80px) !important;
}

.text-bg-dark a {
  color: white;
}

.logo {
  width: 130px;
}

body {
  /* font-family: Montserrat; */
  font-size: 16px;

  font-family: "acumin-pro-condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
  background: #f5f5f5;
}

i {
  font-size: 20px;
  padding: 10px;
}

.btn i {
  font-size: 16px;
  padding: 0;
}

.btn {
  margin: 0;
  border-radius: 0;
}

.sidebar {
  width: 200px;
}

.sidebar a {
  list-style: none;
  padding: 15px 15px;
  background-color: #f5f5f5;
  margin-bottom: 5px;
}

.sidebar a {
  text-decoration: none;
}

.sidebar ul {
  padding: 0;
  margin-top: 3em;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
}

.vis-timeline {
  font-size: 13px;
  border: 0 !important;
}

.vis-panel.vis-center {
  background-color: #91ff5f7a !important; // #c8fdb07a;
}
.vis-item {
  background-color: #ce424280 !important; //#f26868 !important;
  border-style: 1px solid grey !important;
  color: white !important;
  border-radius: 0px !important;

  //border-left: 1px solid white !important;
  //border-right: 1px solid white !important;
  //border: 1px solid #d52121 !important;
  //margin-bottom: 2px;
  &.groupDisabled {
    background-color: #8c8c8c99 !important;
  }
}

.vis-item.vis-selected {
  background-color: #313131 !important;
  //border: 2px solid #d72e2ecc;
}

.vis-item.abgerechnet {
  background-color: #4da833bd !important;
  //border: 1px solid #4a9226 !important;
}

.vis-labelset .vis-label .vis-inner {
  padding: 2px !important;
}

.vis-item {
  .vis-item-content {
    padding: 2px !important;
  }

  
}

.vis-inner span {
  display: inline-block;
  padding-right: 5px;
}
.vis-inner span:nth-child(1) {
  width: 40px;
}
.vis-inner span:nth-child(2) {
  width: 40px;
}
.vis-inner span:nth-child(3) {
  width: 100px;
}

.vis-inner span:nth-child(5) {
  width: 160px;
}

.vis-inner span:nth-child(6) {
  width: 50px;
}

.vis-time-axis{
  .vis-grid {
  border-color: #1e1e1e !important;
  }
}

.vis-inner {
  .activecode {
    background-color: #dbf7db;
    padding: 0 3px !important;
    letter-spacing: 0.1em;
    width: 33px;
  }
  .newcode {
    background-color: #c9dbe3;
    padding: 0 3px !important;
    letter-spacing: 0.1em;
    width: 33px;
  }
}

.groupDisabled {
  background-color: #b7b7b799;
}

tr {
  .wohnungsart {
    width: 60px;
    height: 24px;
    background-color: black;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-right: 20px;
    border-radius: 4px;
    text-transform: uppercase;
  }
  .eigene {
    background-color: rgb(69, 79, 156);
  }

  .fremd {
    background-color: rgb(62, 62, 62);
  }
  .ferien {
    background-color: rgb(253, 203, 0);
  }
}
.vis-inner {
  .eigene .wohnungsart {
    background-color: rgb(69, 79, 156);
  }

  .fremd .wohnungsart {
    background-color: rgb(62, 62, 62);
  }
  .ferien .wohnungsart {
    background-color: rgb(253, 203, 0);
  }
  .wohnungsart {
    width: 16px;
    height: 18px;
    background-color: black;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-right: 20px;
  }
}

.vis-time-axis .vis-grid.vis-odd {
  background: #f5f5f5;
}
.vis-time-axis .vis-grid.vis-saturday,
.vis-time-axis .vis-grid.vis-sunday {
  background: rgb(215, 215, 215);
}

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffffba;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}

.form-control,
.form-select {
  border-radius: 0 !important;
}

.form-switch {
  padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  //display: flex;
  flex-direction: column;
}
</style>

<!-- <template>
  <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import Wohnungen from './components/Wohnungen.vue'

export default {
  name: 'App',
  components: {
    Wohnungen
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
 -->
