// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
/* import { JUNKER_API_KEY } from "./junker.js";

import axios from "axios"; // Stellen Sie sicher, dass axios installiert ist */



// Junker-Auen Firebase DB
/* const firebaseConfig = {
  apiKey: "AIzaSyA0Q9Unh99y8ZZ_TR3Kidi35dMHviJY_qU",
  authDomain: "junker-auen.firebaseapp.com",
  databaseURL: "https://junker-auen-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "junker-auen",
  storageBucket: "junker-auen.appspot.com",
  messagingSenderId: "1093101233950",
  appId: "1:1093101233950:web:668abfd7f7a9cab5070b0f",
}; */

const firebaseConfig = {

  apiKey: "AIzaSyC2snz4JVHwoAlkEUxEs9oiNn9ydF-E4v4",
  authDomain: "rental-ja.firebaseapp.com",
  databaseURL: "https://rental-ja-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "rental-ja",
  storageBucket: "rental-ja.appspot.com",
  messagingSenderId: "964519017122",
  appId: "1:964519017122:web:45806e1e5053c3cf9135fb"

};


const apiConfig = {
  /* apiUrl: "https://junker.vogel.media", */
  apiUrl: "https://api.junker-auen.de",

};
// Funktion zur Abfrage der Firebase-Konfiguration von der API
/* async function getFirebaseConfig() {
  try {
    const response = await axios.get(`https://api.junker-auen.de/api/firebaseconfig?apiKey=${JUNKER_API_KEY}`);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Abrufen der Firebase-Konfiguration: ", error);
    // Hier sollten Sie Fehlerbehandlung hinzufügen
  }
} */


/* // Funktion zur Initialisierung von Firebase
//async function initializeFirebase() {
  const firebaseConfig = await getFirebaseConfig();
  let database = {};

  if (firebaseConfig) {
    const app = initializeApp(firebaseConfig);
    database = getDatabase(app);
    //return { app, database };
  } else {
    console.error("Firebase-Konfiguration konnte nicht geladen werden.");
    // Weitere Fehlerbehandlung hier
  }
//} */

// Firebase initialisieren, wenn die App geladen wird
//initializeFirebase();



// Initialisieren von Firebase
const app = initializeApp(firebaseConfig);

// Zugriff auf die Firebase Realtime Database
const database = getDatabase(app);

export { database, firebaseConfig, apiConfig };
