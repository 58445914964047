<template>
  <div v-if="getVisibleState" class="dialog-overlay">
    <div class="dialog" :class="headlineClass">
      <div class="dialog-header">{{ headlineText }}</div>
      <div class="dialog-body">
        <p v-html="linkifiedMessage"></p>
      </div>
      <div class="dialog-footer">
        <button class="btn btn-outline-secondary" @click="closeMsgDialog">
          Schließen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      headline: "Info",
    };
  },
  computed: {
    ...mapGetters(["getVisibleState", "getMessage", "getType"]),

    computedClass() {
      const type = this.getType;
      return {
        type,
      };
    },

    headlineText() {
      if (this.getType === "error") {
        return "Fehler";
      } else if (this.getType === "info") {
        return "Info";
      } else return "Info";
    },
    headlineClass() {
      if (this.getType === "error") {
        return "error";
      } else if (this.getType === "info") {
        return "info";
      } else return "";
  },
    computedHeadline() {
      let headline = "";
      if (this.getType === "info") {
        headline = "Info";
      }
      if (this.getType === "error") {
        headline = "Fehler";
      }

      return headline;
    },

    linkifiedMessage() {
      return this.linkify(this.message);
    },
    /* isMsgDialogVisible() {
      return this.$store.state.isMsgDialogVisible;
    }, */
    message() {
      return this.getMessage;
    },
    type() {
      return this.getType;
    },
  },
  methods: {
    ...mapActions(["triggerMsgDialog", "closeMsgDialog"]),

    linkify(inputText) {
      const urlRegex = `/(\b(https?|ftp|file)://[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig`;
      return inputText.replace(urlRegex, function (url) {
        return (
          '<a href="' +
          url +
          '" target="_blank" rel="noopener noreferrer">' +
          url +
          "</a>"
        );
      });
    },

    closeMsgDialog() {
      this.$store.dispatch("closeMsgDialog");
    },
  },
  watch: {
    isMsgDialogVisible: {
      deep: true,
      handler() {
        this.isMsgDialogVisible = this.$store.state.isMsgDialogVisible;
      },
    },
  },
};
</script>
